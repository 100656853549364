import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Ul,
	Paragraph,
	TextContainer,
	Card,
	Content,
	Link,
	Icon,
	IconPotentialIssue,
	IconResolvedIssue,
	Badge,
} from "../../..";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Code,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Tabs, useTabSet } from "./tabs";

export const Meta: DocPageMeta = {
	category: "Navigation",
	title: "Tabs",
	notepad: "https://hackmd.io/8PIGatosRHKYbufchnt36w",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Tabs"
				subTitle="Use tabs to organize related content into views that users can navigate between. Tabs enable users to switch between views while remaining within the same context on a page."
			/>
			<ContentSection>
				<TextContainer article>
					<ImportExample component={Tabs} />
					<InlineMessage variant="warning">
						<Paragraph>
							To use <Code>Tabs</Code> in PFG, use the{" "}
							<Link
								inlineInText
								href="https://github.com/Siteimprove/PfgFramework/blob/master/PfgFramework.Client.Web/FancyPfg/src/shared/components/tab-wrapper/tab-wrapper.tsx"
							>
								FancyPFG TabWrapper
							</Link>{" "}
							component instead. This component provides access to <Code>Tabs</Code> and provides
							PFG routing functionality.{" "}
						</Paragraph>
					</InlineMessage>
					<Header.H2>Examples</Header.H2>
					<Paragraph>
						The <Code>Tabs</Code> component consists of two parts: the tabs and their contents.
						These two parts can either be nested within the same container (
						<a href="#container-level">container-level tabs</a>) or separate containers (
						<a href="#page-level">page-level tabs</a>). Furthermore, the contents of a tab is by
						default unmounted when a different tab is selected. However, it's also possible to keep
						tab contents <a href="#mounted">mounted</a>. Lastly, tab labels can be accompanied by
						both <a href="#icons">icons</a> and <a href="#badges">badges</a>. All these options are
						showcased in the following sections.
					</Paragraph>
					<Header.H3>Default</Header.H3>
					<Paragraph>
						This is the default implementation of the <Code>Tabs</Code> component. It doesn’t look
						great. This is partly because the content doesn't have any padding. This can be added by
						using a structure component, such as a{" "}
						<GatsbyLink to="/lib/components/Structure/Content">Content</GatsbyLink> component.
						However, the main reason is that <Code>Tabs</Code> are meant to be nested within a
						container, such as a <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>.
						This is showcased in the next section.
					</Paragraph>
					<Example fn={DefaultExample} />
					<Header.H3>Container-level</Header.H3>
					<Paragraph>
						Here you seen an example of container-level tabs. That means the <Code>Tabs</Code>{" "}
						component’s two parts, the tabs and their contents, are both nested within the same
						container. In this case, a{" "}
						<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> component. This
						container could also have been a{" "}
						<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> or{" "}
						<GatsbyLink to="/lab/components/Overlay/Side panel">Side Panel</GatsbyLink>.
					</Paragraph>
					<Paragraph>
						A <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> with container-level
						tabs should always feature a <Code>Card.Header</Code> component. In other words,
						container-level tabs should not be used as a substitute for a <Code>Card.Header</Code>.
					</Paragraph>
					<Example fn={ContainerLevelExample} />
					<Header.H3>Page-level</Header.H3>
					<Paragraph>
						Here you see an example of page-level tabs. That means the <Code>Tabs</Code> component’s
						two parts, the tabs and their contents, are nested in different containers. The tabs are
						nested in a <GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> component
						by themselves. The tabs’ contents are nested in separate <Code>Cards</Code>.
					</Paragraph>
					<Paragraph>
						This configuration enables you to trigger the visibility of multiple <Code>Cards</Code>{" "}
						on a page. Page-level tabs should only be used directly on a page &mdash; not in an
						overlay, such as a <GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> or{" "}
						<GatsbyLink to="/lab/components/Overlay/Side panel">Side Panel</GatsbyLink>.
					</Paragraph>
					<Example fn={PageLevelExample} />
					<Header.H3>Mounted</Header.H3>
					<Paragraph>
						The contents of a tab is by default unmounted when a different tab is selected. However,
						it's also possible to keep tab contents mounted . This is done by setting the{" "}
						<Code>keepTabsMounted</Code> property to <Code>true</Code>, as shown in the example
						below.
					</Paragraph>
					<Example fn={MountedExample} />
					<Header.H3>Tabs with data-observe-keys</Header.H3>
					<Paragraph>
						If you want to track the activity of the individual tabs, you can assign a{" "}
						<code>data-observe-key</code> to each tab, as shown in the example below.
					</Paragraph>
					<Example fn={DataObserveKeyExample} />
					<Header.H3>Badges</Header.H3>
					<Paragraph>
						Here you seen an example of how to add{" "}
						<GatsbyLink to="/lib/components/Visuals/Badge">badges</GatsbyLink> to tabs. Badges are
						used to indicate the status or category of a UI element. Place them to the right of tab
						labels and set their <Code>size</Code> prop to <Code>small</Code>.
					</Paragraph>
					<Example fn={BadgesExample} />
					<Header.H3>Icons</Header.H3>
					<Paragraph>
						Here you seen an example of how to add{" "}
						<GatsbyLink to="/lib/components/Visuals/Icon">icons</GatsbyLink> to tabs. The icons
						should improve scannability or clarify the meaning of the tab labels. They should also
						always be placed to the left of tab labels.
					</Paragraph>
					<Example fn={IconsExample} />
					<Header.H2>Properties</Header.H2>
					<Knobs
						component={Tabs}
						initialProps={({ setState }) => ({
							selectedTab: 0,
							onChange: (v) => setState({ selectedTab: v }),
							tabs: [
								{
									header: "Tab 1",
									content: <Paragraph>This is the content for Tab 1.</Paragraph>,
								},
								{
									header: "Tab 2",
									content: <Paragraph>This is the content for Tab 2.</Paragraph>,
								},
							],
						})}
					/>
					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Header.H4>General</Header.H4>
						<Ul
							items={[
								"Use tabs to organize related content into views that users can navigate between.",
								"Use tabs to help users find specific content, while remaining within the same context on a page.",
								"Make sure the first tab is the one that's selected by default.",
								"Order the tabs in a way that helps users find the content they’re looking for.",
							]}
						/>
						<Header.H4>Container-level vs. page-level tabs</Header.H4>
						<Ul
							items={[
								<>
									Nest the <Code>Tab</Code> component’s two parts, the tabs and their contents,
									within either the same container (container-level tabs) or separate containers
									(page-level tabs).
								</>,
								<>
									Nest container-level tabs in a{" "}
									<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>,{" "}
									<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>, or{" "}
									<GatsbyLink to="/lab/components/Overlay/Side panel">Side Panel</GatsbyLink>.
								</>,
								<>
									Always provide <GatsbyLink to="/lib/components/Structure/Card">Cards</GatsbyLink>{" "}
									containing container-level tabs with a <Code>Card.Header</Code> component.
								</>,
								<>
									Nest page-level tabs in{" "}
									<GatsbyLink to="/lib/components/Structure/Card">Cards</GatsbyLink>.
								</>,
								<>
									Make sure all <GatsbyLink to="/lib/components/Structure/Card">Cards</GatsbyLink>{" "}
									placed beneath beneath page-level tabs are part of the contents of those tabs.
								</>,
								"Try to avoid having multiple page-level tabs on a page. If unavoidable, nest them inside each other. That means some page-level tabs would function as the contents of other page-level tabs.",
								"Choose page-level tabs when a use case can be solved both by container-level and page-level tabs.",
							]}
						/>
						<Header.H4>Icons</Header.H4>
						<Ul
							items={[
								<>
									Add Icons to tab labels if that improves scannability or clarifies the labels'
									meaning.
								</>,
								<>Don’t use icons as a replacement for tab labels.</>,
								<>Don’t add icons to tab labels because of aesthetic reasons.</>,
								<>Place icons to the left of tab labels.</>,
								<>Either provide all of the tab labels with icons or none of them.</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul
							items={[
								"Users don't need to stay within the same context. Instead, show the contents on different pages.",
								<>
									The number of tabs exceeds seven. Instead, consider combining the contents of some
									tabs or using multiple <Code>Tabs</Code> components.
								</>,
								<>
									Users need to switch between different views of the same data. Instead, use a{" "}
									<GatsbyLink to="/lab/components/Actions and controls/Toggle Group">
										Toggle Group
									</GatsbyLink>
								</>,
								<>
									Users need to switch between different views within part of a{" "}
									<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>. Instead, use a{" "}
									<GatsbyLink to="/lab/components/Actions and controls/Toggle Group">
										Toggle Group
									</GatsbyLink>
									.
								</>,
								<>
									Users need to compare the contents of different tabs. Instead, use a{" "}
									<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> or{" "}
									<GatsbyLink to="/lab/components/Tables and lists/List table">
										List table
									</GatsbyLink>
									.
								</>,
								<>
									Users need to be guided through a series of steps in a flow. In the future, Fancy
									will offer a component for this use case. For now, you’ll need to create such a
									component yourself.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Paragraph>
							Provide each tab with a unique and short label that clearly describes its content.
						</Paragraph>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul
							items={[
								<>Keep tab labels short &mdash; 1 to 3 words.</>,
								<>
									Make sure tab labels make sense within their context of use. For instance, labels
									such as "Active" and "Inactive" are clear when used on a page within the Policy
									product.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H2>Notable Changes</Header.H2>
					<Header.H3>Version 0.0.x</Header.H3>
					<Ul
						items={[
							<>
								The <Code>CardTabbed</Code> and <Code>PageTabs</Code> components have been removed.
								Instead, there's now just a single component: <Code>Tabs</Code>. It can be
								configured to function as <a href="#container-level">container-level</a> or{" "}
								<a href="#page-level">page-level</a> tabs.
							</>,
						]}
					/>
				</TextContainer>
			</ContentSection>
		</>
	);
};

const DefaultExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<Tabs
			selectedTab={selectedTab}
			onChange={setSelectedTab}
			tabs={[
				{
					header: "Tab 1",
					content: <Paragraph>This is the content for Tab 1.</Paragraph>,
				},
				{
					header: "Tab 2",
					content: <Paragraph>This is the content for Tab 2.</Paragraph>,
				},
			]}
		/>
	);
};

const ContainerLevelExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<Card>
			<Card.Header>Card header</Card.Header>
			<Tabs
				selectedTab={selectedTab}
				onChange={setSelectedTab}
				tabs={[
					{
						header: "Tab 1",
						content: (
							<Content>
								<Paragraph>This is the content for Tab 1.</Paragraph>
							</Content>
						),
					},
					{
						header: "Tab 2",
						content: (
							<Content>
								<Paragraph>This is the content for Tab 2.</Paragraph>
							</Content>
						),
					},
				]}
			/>
		</Card>
	);
};

const PageLevelExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [pageTabs, tabContent] = useTabSet({
		selectedTab,
		onChange: setSelectedTab,
		tabs: [
			{
				header: "Tab 1",
				content: (
					<>
						<Card>
							<Card.Header>Card A</Card.Header>
							<Content>
								<Paragraph>This is the content for Card A belonging to Tab 1.</Paragraph>
							</Content>
						</Card>
						<Card>
							<Card.Header>Card B</Card.Header>
							<Content>
								<Paragraph>This is the content for Card B belonging to Tab 1.</Paragraph>
							</Content>
						</Card>
					</>
				),
			},
			{
				header: "Tab 2",
				content: (
					<>
						<Card>
							<Card.Header>Card C</Card.Header>
							<Content>
								<Paragraph>This is the content for Card C belonging to Tab 2.</Paragraph>
							</Content>
						</Card>
						<Card>
							<Card.Header>Card D</Card.Header>
							<Content>
								<Paragraph>This is the content for Card D belonging to Tab 2.</Paragraph>
							</Content>
						</Card>
					</>
				),
			},
		],
	});
	return (
		<>
			<Card>{pageTabs}</Card>
			{tabContent}
		</>
	);
};

const MountedExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [pageTabs, tabContent] = useTabSet({
		selectedTab,
		keepTabsMounted: true,
		onChange: setSelectedTab,
		tabs: [
			{
				header: "Tab 1",
				content: (
					<>
						<Card>
							<Card.Header>Card A</Card.Header>
							<Content>
								<Paragraph>
									This Card belongs to Tab 1 and will remain mounted when you select Tab 2.
								</Paragraph>
							</Content>
						</Card>
					</>
				),
			},
			{
				header: "Tab 2",
				content: (
					<>
						<Card>
							<Card.Header>Card B</Card.Header>
							<Content>
								<Paragraph>
									This Card belongs to Tab 2 and will remain mounted when you select Tab 1.
								</Paragraph>
							</Content>
						</Card>
					</>
				),
			},
		],
	});
	return (
		<>
			<Card>{pageTabs}</Card>
			{tabContent}
		</>
	);
};

const DataObserveKeyExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<Tabs
			selectedTab={selectedTab}
			onChange={setSelectedTab}
			tabs={[
				{
					header: "Tab 1",
					content: <Paragraph>This is the content for Tab 1.</Paragraph>,
					"data-observe-key": "tab-1",
				},
				{
					header: "Tab 2",
					content: <Paragraph>This is the content for Tab 2.</Paragraph>,
					"data-observe-key": "tab-2",
				},
			]}
		/>
	);
};

const BadgesExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<Card>
			<Card.Header>Tasks</Card.Header>
			<Tabs
				selectedTab={selectedTab}
				onChange={setSelectedTab}
				tabs={[
					{
						header: (
							<>
								To do
								<Badge type="subtle" size="small" variant="light">
									3
								</Badge>
							</>
						),
						content: (
							<Content>
								<Ul items={["Task 1", "Task 2", "Task 3"]} />
							</Content>
						),
					},
					{
						header: (
							<>
								Doing
								<Badge type="neutral" size="small" variant="light">
									2
								</Badge>
							</>
						),
						content: (
							<Content>
								<Ul items={["Task 1", "Task 2"]} />
							</Content>
						),
					},
					{
						header: (
							<>
								Done
								<Badge type="positive" size="small" variant="light">
									5
								</Badge>
							</>
						),
						content: (
							<Content>
								<Ul items={["Task 1", "Task 2", "Task 3", "Task 4", "Task 5"]} />
							</Content>
						),
					},
				]}
			/>
		</Card>
	);
};

const IconsExample = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<Card>
			<Card.Header>Issues</Card.Header>
			<Tabs
				selectedTab={selectedTab}
				onChange={setSelectedTab}
				tabs={[
					{
						header: (
							<>
								<Icon>
									<IconPotentialIssue />
								</Icon>
								Potential
							</>
						),
						content: (
							<Content>
								<Ul
									items={[
										"Potential issue 1",
										"Potential issue 2",
										"Potential issue 3",
										"Potential issue 4",
										"Potential issue 5",
									]}
								/>
							</Content>
						),
					},
					{
						header: (
							<>
								<Icon>
									<IconResolvedIssue />
								</Icon>
								Resolved
							</>
						),
						content: (
							<Content>
								<Ul
									items={[
										"Resolved issue 1",
										"Resolved issue 2",
										"Resolved issue 3",
										"Resolved issue 4",
										"Resolved issue 5",
									]}
								/>
							</Content>
						),
					},
				]}
			/>
		</Card>
	);
};
